var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("el-input", {
              staticClass: "filter-item",
              staticStyle: { width: "200px", "margin-bottom": "0" },
              attrs: {
                "prefix-icon": "el-icon-search",
                size: "small",
                placeholder: "关键字",
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleFilter.apply(null, arguments)
                },
              },
              model: {
                value: _vm.listQuery.key,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "key", $$v)
                },
                expression: "listQuery.key",
              },
            }),
            _c("permission-btn", {
              attrs: { size: "mini" },
              on: { "btn-event": _vm.onBtnClicked },
            }),
            _c(
              "el-checkbox",
              {
                staticStyle: { "margin-left": "15px" },
                attrs: { size: "mini" },
                on: {
                  change: function ($event) {
                    _vm.tableKey = _vm.tableKey + 1
                  },
                },
                model: {
                  value: _vm.showDescription,
                  callback: function ($$v) {
                    _vm.showDescription = $$v
                  },
                  expression: "showDescription",
                },
              },
              [_vm._v("Id/描述")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "el-row",
            { staticClass: "fh", attrs: { gutter: 4 } },
            [
              _c(
                "el-col",
                { staticClass: "fh ls-border", attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "body-small fh",
                      staticStyle: { overflow: "auto" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { padding: "0 11px" },
                              attrs: { type: "text" },
                              on: { click: _vm.getAllUsers },
                            },
                            [_vm._v("全部用户>>")]
                          ),
                        ],
                        1
                      ),
                      _c("el-tree", {
                        attrs: {
                          data: _vm.orgsTree,
                          "expand-on-click-node": false,
                          "default-expand-all": "",
                          props: _vm.defaultProps,
                        },
                        on: { "node-click": _vm.handleNodeClick },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { staticClass: "fh", attrs: { span: 18 } }, [
                _c(
                  "div",
                  { staticClass: "bg-white fh" },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.listLoading,
                            expression: "listLoading",
                          },
                        ],
                        key: _vm.tableKey,
                        ref: "mainTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.list,
                          border: "",
                          fit: "",
                          "highlight-current-row": "",
                          height: "calc(100% - 52px)",
                        },
                        on: {
                          "row-click": _vm.rowClick,
                          "selection-change": _vm.handleSelectionChange,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            type: "selection",
                            width: "55",
                          },
                        }),
                        _vm.showDescription
                          ? _c("el-table-column", {
                              attrs: { label: "Id", "min-width": "120px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.id)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                773642443
                              ),
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: { "min-width": "80px", label: "账号" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "link-type",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdate(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.account))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { "min-width": "80px", label: "姓名" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.name))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: { width: "120px", label: "所属部门" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.organizations)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.showDescription
                          ? _c("el-table-column", {
                              attrs: { "min-width": "150px", label: "描述" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.description)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3736829645
                              ),
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            "class-name": "status-col",
                            label: "状态",
                            width: "100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: _vm._f("statusFilter")(
                                        scope.row.status
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.statusOptions.find(function (u) {
                                            return u.key == scope.row.status
                                          }).display_name
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "操作",
                            width: "230",
                            "class-name": "small-padding fixed-width",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleUpdate(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  scope.row.status == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleModifyStatus(
                                                scope.row,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("停用")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.total > 0,
                          expression: "total>0",
                        },
                      ],
                      attrs: {
                        total: _vm.total,
                        page: _vm.listQuery.page,
                        limit: _vm.listQuery.limit,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.listQuery, "page", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.listQuery, "limit", $event)
                        },
                        pagination: _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini",
              attrs: {
                width: "500px",
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.temp,
                    "label-position": "right",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dialogStatus == "update",
                          expression: "dialogStatus=='update'",
                        },
                      ],
                      attrs: { size: "small", label: "Id", prop: "id" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: true,
                          size: "small",
                          placeholder: "系统自动处理",
                        },
                        model: {
                          value: _vm.temp.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "id", $$v)
                          },
                          expression: "temp.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { size: "small", label: "账号", prop: "account" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.temp.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "account", $$v)
                          },
                          expression: "temp.account",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "姓名" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.temp.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "name", $$v)
                          },
                          expression: "temp.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "密码" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            _vm.dialogStatus == "update"
                              ? "如果为空则不修改密码"
                              : "如果为空则默认与账号相同",
                        },
                        model: {
                          value: _vm.temp.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "password", $$v)
                          },
                          expression: "temp.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: { placeholder: "Please select" },
                          model: {
                            value: _vm.temp.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "status", $$v)
                            },
                            expression: "temp.status",
                          },
                        },
                        _vm._l(_vm.statusOptions, function (item) {
                          return _c("el-option", {
                            key: item.key,
                            attrs: {
                              label: item.display_name,
                              value: item.key,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "所属机构" } },
                    [
                      _vm.dialogFormVisible
                        ? _c("treeselect", {
                            attrs: {
                              options: _vm.orgsTree,
                              "default-expand-level": 3,
                              multiple: true,
                              flat: true,
                              "open-on-click": true,
                              "open-on-focus": true,
                              "clear-on-select": true,
                            },
                            model: {
                              value: _vm.selectOrgs,
                              callback: function ($$v) {
                                _vm.selectOrgs = $$v
                              },
                              expression: "selectOrgs",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { size: "small", label: "描述" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 4 },
                          placeholder: "Please input",
                        },
                        model: {
                          value: _vm.temp.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "description", $$v)
                          },
                          expression: "temp.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.dialogStatus == "create"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.createData },
                        },
                        [_vm._v("确认")]
                      )
                    : _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.updateData },
                        },
                        [_vm._v("确认")]
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              staticClass: "dialog-mini body-small",
              attrs: {
                width: "516px",
                title: "分配角色",
                visible: _vm.dialogRoleVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogRoleVisible = $event
                },
              },
            },
            [
              _vm.dialogRoleVisible
                ? _c(
                    "el-form",
                    {
                      ref: "rolesForm",
                      attrs: { size: "small", "label-position": "left" },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("select-roles", {
                            attrs: {
                              roles: _vm.selectRoles,
                              userNames: _vm.selectRoleNames,
                            },
                            on: { "roles-change": _vm.rolesChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.dialogRoleVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.acceRole },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }